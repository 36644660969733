<script setup>
import ProjectDimensions from '@/components/quote/ProjectDimensions/ProjectDimensions.vue'
import { ref, computed, onMounted, onBeforeUnmount, watch, nextTick } from 'vue'
import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'
import { useStore } from 'vuex'
import RouteEntityContext from '@/components/composables/RouteEntityContext.js'
import EntityComputedFields from '@/components/composables/EntityFields/EntityComputedFields.js'
import eventBus from '@/eventBus.js'
import { useSidePanel } from '@/stores/sidepanel.js'

const activeTab = ref(null)

const $store = useStore()

const inGlobalScope = computed(() => {
  return $store.state.session.user.user_is_super_user && !$store.state.session.scope.company
})

const { refId, type, storeName, normalized } = RouteEntityContext.useRouteEntityContext({
  trackChanges: false
})

const {
  quote_profit_net,
  quote_price_net,
  quote_price_tax,
  quote_price_gross,
  quote_discount_percentage,
  quote_discount_net,
  quote_profit_percentage,
  quote_markup_net,
  quote_margin_net,
  quote_total_cost_net,
  provinceId,
  taxId
} = EntityComputedFields.useEntityComputedFields({
  refId,
  type,
  store: storeName
})
const quoteTotalCosts = computed(() => quote_total_cost_net.value)

const quoteProfit = computed({
  get: () => c.toNum(quote_profit_net.value, 4),
  set: (val) => (quote_profit_net.value = val)
})
const profitPercentage = computed({
  get: () => c.toNum(quote_margin_net.value * 100, 4),
  set: (val) => (quote_profit_percentage.value = val / 100)
})
const quoteMarkup = computed({
  get: () => c.toNum(c.marginToMarkup(quote_margin_net.value), 4),
  set: (val) => {
    profitPercentage.value = c.markupToMargin(val) * 100
  }
})
const quoteNet = computed({
  get: () => quote_price_net.value,
  set: (val) => (quote_price_net.value = val)
})
const quoteGross = computed({
  get: () => quote_price_gross.value,
  set: (val) => (quote_price_gross.value = val)
})
const quoteTax = computed({
  get: () => quote_price_tax.value,
  set: (val) => (quote_price_tax.value = val)
})
const discountPercent = computed({
  get: () => c.toNum(quote_discount_percentage.value, 4) * 100,
  set: (val) => (quote_discount_percentage.value = val)
})
const quoteDiscount = computed({
  get: () => quote_discount_net.value,
  set: (val) => (quote_discount_net.value = val)
})
// const targetMarkup = computed({
//   get: () => c.toNum(quote_markup_net.value, 4),
//   set: (val) => (quote_markup_net.value = val)
// })
const targetMargin = computed({
  get: () => c.toNum(c.markupToMargin(quote_markup_net.value), 4),
  set: (val) => (targetMargin.value = val)
})
// const quotePostal = computed({
//   get: () => quote_postal.value,
//   set: (val) => (quote_postal.value = val)
// })
// const quoteAdjustment = computed({
//   get: () => quote_markup_percentage_adjustment.value,
//   set: (val) => (quote_markup_percentage_adjustment.value = val)
// })
// const assemblyAdjustment = computed({
//   get: () => assembly_markup_percentage_adjustment.value,
//   set: (val) => (assembly_markup_percentage_adjustment.value = val)
// })

const emptyTaxSet = ref(null)
const taxIsEmpty = () => {
  emptyTaxSet.value = true
}
const taxIsNotEmpty = () => {
  emptyTaxSet.value = false
}
const createSalesTax = async () => {
  const tax = await $store.dispatch('create', {
    type: 'tax',
    embue: {
      province_id: provinceId.value || $store.state.session.company.province_id
    }
  })

  this.taxId = tax.tax_id

  return this
}

// const showAllDims = ref(false)

const sidePanelStore = useSidePanel()
const accordionPt = computed(() => ({
  root: () => ({}),
  tab: () => ({}),
  accordionTab: () => ({
    header: () => ({
      class: ['bg-pitch-black', 'p-0 h-fit']
    }),
    ...(sidePanelStore.collapsed
      ? {
          headerIcon: () => ({
            class: [
              {
                hidden: sidePanelStore.collapsed
              }
            ]
          })
        }
      : {}),
    headerAction: () => ({
      class: [
        'h-full w-full inline-flex items-center ',
        'font-normal text-flame-white',
        {
          'p-4 justify-start ': !sidePanelStore.collapsed,
          'px-1 py-4 justify-center': sidePanelStore.collapsed
        }
      ]
    }),
    content: () => ({
      class: ['!p-0']
    })
  }),
  hooks: () => ({})
}))

const openDimensions = () => {
  activeTab.value = [0]
}
const closeDimensions = () => {
  activeTab.value = null
}

onMounted(() => {
  eventBus.$on(`closeDimensions`, closeDimensions)
  eventBus.$on(`openDimensions`, openDimensions)
})
onBeforeUnmount(() => {
  eventBus.$off(`closeDimensions`, closeDimensions)
  eventBus.$off(`openDimensions`, openDimensions)
})

const dimensionalAreas = computed(() => {
  const items = normalized.value

  return Object.keys(items).filter(
    (refId) =>
      items[refId] &&
      (items[refId].type === 'assembly' || items[refId].type === 'quote') &&
      items[refId].asRequiredDimensions?.length
  )
})

const noticeMissingDimensions = computed(() => {
  const rooms = dimensionalAreas.value

  return rooms.reduce((count, room) => {
    const dims = normalized.value[room].oDimensions
    const roomCount = normalized.value[room].asRequiredDimensions.filter(
      (abbr) => !dims[abbr] || !dims[abbr].value
    )
    return count + roomCount.length
  }, 0)
})

watch(activeTab, () => {
  sidePanelStore.collapsed = false
  sidePanelStore.open = true
})

watch(
  () => sidePanelStore.collapsed,
  async (collapsing, notBefore) => {
    if (collapsing && !notBefore) {
      activeTab.value = null
      await nextTick()
      sidePanelStore.collapsed = true
      eventBus.$emit('toggle-quote-sidepanel', true)
    } else {
      eventBus.$emit('toggle-quote-sidepanel', false)
    }
  }
)
</script>

<template>
  <Accordion v-model:activeIndex="activeTab" :pt="accordionPt" multiple>
    <AccordionTab>
      <template #header>
        <div
          :class="[
            'flex items-center gap-1 w-full',
            {
              'justify-between ': !sidePanelStore.collapsed,
              'justify-center ': sidePanelStore.collapsed
            }
          ]"
        >
          <template v-if="sidePanelStore.collapsed">
            <font-awesome-icon icon="ruler-triangle" />
          </template>
          <template v-else> Project dimensions </template>

          <font-awesome-icon
            icon="fas fa-circle"
            class="text-deep-red-500 animate-pulse"
            v-if="noticeMissingDimensions"
          />
        </div>
      </template>
      <template #default>
        <div class="bg-cement-100">
          <!-- hide when not visible for performance reasons -->
          <ProjectDimensions
            v-if="activeTab?.includes?.(0)"
            :reference="refId"
            :key="refId"
            :showDerivedDimensions="true"
            :store="storeName"
          />
        </div>
      </template>
    </AccordionTab>
    <AccordionTab v-if="!inGlobalScope">
      <template #header>
        <div
          :class="[
            'flex items-center gap-1 w-full',
            {
              'justify-between ': !sidePanelStore.collapsed,
              'justify-center ': sidePanelStore.collapsed
            }
          ]"
        >
          <span v-if="!sidePanelStore.collapsed"> Project totals </span>

          <CalculatorFormatted
            :class="[
              {
                'text-xs': sidePanelStore.collapsed
              }
            ]"
            class="pointer-events-none"
            format="currency"
            v-model="quoteGross"
          />
        </div>
      </template>
      <template #default>
        <div class="bg-cement-100 px-4 py-6">
          <div class="flex flex-col justify-start items-stretch gap-4 py-2">
            <div class="flex justify-between items-center">
              <div class="text-cool-gray-700 flex flex-col justify-center items-start">
                Budgeted costs
                <!--                    <a href="" class="text-xs">Show details</a>-->
              </div>

              <div class="text-danger value">
                {{ $f.currency(quoteTotalCosts) }}
              </div>
            </div>

            <div class="flex justify-between items-center">
              <div class="text-cool-gray-700">Discount</div>

              <div class="flex flex-row items-center justify-center">
                <CalculatorFormatted format="number" v-model="discountPercent" />
                %
              </div>

              <div class="value">
                <CalculatorFormatted format="currency" v-model="quoteDiscount" />
              </div>
            </div>

            <div class="flex justify-between items-center">
              <div class="text-cool-gray-700">Profit</div>

              <div class="flex flex-row items-center justify-center flex-grow">
                &times;
                <CalculatorFormatted
                  style="max-width: 100px"
                  format="number"
                  v-model="quoteMarkup"
                />
                &nbsp;&nbsp;or&nbsp;&nbsp;
                <CalculatorFormatted
                  style="max-width: 100px"
                  format="number"
                  v-model="profitPercentage"
                />
                %
              </div>

              <div class="text-success value">
                <CalculatorFormatted format="currency" v-model="quoteProfit" />
              </div>
            </div>

            <div class="h-0 border-b border-surface-200"></div>

            <div class="flex justify-between items-center">
              <div class="text-cool-gray-700">Subtotal</div>

              <div class="flex flex-row items-center justify-center"></div>

              <div class="value">
                <CalculatorFormatted format="currency" v-model="quoteNet" />
              </div>
            </div>

            <div class="flex justify-between items-center">
              <div class="text-cool-gray-700">Tax</div>

              <div class="flex flex-row items-center justify-center">
                <Choose
                  v-show="!emptyTaxSet"
                  schema="tax:tax_id"
                  size="xs"
                  ref="taxSelect"
                  :filters="{
                    province_id: `${provinceId || $store.state.session.company.province_id}||NULL`
                  }"
                  :allowDeselect="false"
                  :noCarryOver="true"
                  :loadImmediate="true"
                  :default="$store.state.session.company.tax_id"
                  @empty="taxIsEmpty"
                  @not-empty="taxIsNotEmpty"
                  v-model="taxId"
                />
                <Btn link size="xsmall" v-show="emptyTaxSet" @click="createSalesTax"
                  >Set your sales tax...</Btn
                >
              </div>

              <div class="value">
                {{ $f.currency(quoteTax) }}
              </div>
            </div>

            <div class="h-0 border-b border-surface-200"></div>

            <div class="flex justify-between items-center">
              <div class="text-cool-gray-700 strong">Grand total</div>

              <div class="flex flex-row items-center justify-center"></div>

              <div class="value">
                <CalculatorFormatted format="currency" v-model="quoteGross" />
              </div>
            </div>
          </div>
        </div>
      </template>
    </AccordionTab>
  </Accordion>
</template>

<style scoped lang="scss"></style>
